import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Heading from 'components/heading';
import cn from 'classnames';

const themes = {
  circle: 'circle'
};

const RelatedArticlesCarouselSlide = ({ tag, title, image, url, theme }) => {
  return (
    <article className="related-articles-carousel__slide swiper-slide">
      <a href={url} className="related-articles-carousel__slide-link">
        {image && (
          <div
            className={cn('related-articles-carousel__slide-image-container', {
              [`related-articles-carousel__slide-image-container--${theme}`]: theme
            })}
          >
            <Image theme={Image.themes.background} {...image} />
          </div>
        )}
        {tag && (
          <div className="related-articles-carousel__slide-tag">{tag}</div>
        )}
        {title && (
          <Heading
            className={cn('related-articles-carousel__slide-title', {
              [`related-articles-carousel__slide-title--${theme}`]: theme
            })}
            level={3}
          >
            <span className="related-articles-carousel__slide-title-text">
              {title}
            </span>
          </Heading>
        )}
      </a>
    </article>
  );
};

RelatedArticlesCarouselSlide.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  url: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

RelatedArticlesCarouselSlide.themes = themes;

export default RelatedArticlesCarouselSlide;
