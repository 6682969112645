import React from 'react';
import PropTypes from 'prop-types';

// NOTE: Removes scripts, styles and h1-tags (only one h1 tag allowed on each page)
const sanitize = html =>
  html
    .replace(/<script( type="text\/javascript")?>([\s\S]+)<\/script>/g, '')
    .replace(/<style>([\s\S]+)<\/style>/g, '')
    .replace(/<h1>([\s\S]+)<\/h1>/g, '');

const Form = ({ action, attributes, html, method }) => {
  return (
    <form
      action={action}
      className="form"
      method={method}
      dangerouslySetInnerHTML={{ __html: sanitize(html) }}
      {...attributes}
    />
  );
};

Form.propTypes = {
  action: PropTypes.string,
  attributes: PropTypes.objectOf(PropTypes.string),
  html: PropTypes.string,
  method: PropTypes.string
};

Form.defaultProps = {
  html: ''
};

export default Form;
