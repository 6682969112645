import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';

const LoginError = ({ heading, text, link }) => {
  return (
    <div className="login-error">
      {heading && <h2 className="login-error__heading">{heading}</h2>}
      {text && <div className="login-error__text">{text}</div>}
      {link && (
        <a
          className="login-error__link"
          href={link.url}
          target={link.shouldOpenInNewTab ? '_blank' : null}
          rel={link.shouldOpenInNewTab ? 'nofollow noopener noreferrer' : null}
        >
          <span className="login-error__link-text">{link.text}</span>
        </a>
      )}
    </div>
  );
};

LoginError.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};

export default LoginError;
