import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InfoNote from 'components/info-note';
import Label from 'components/label';
import ErrorText from 'components/error-text';
import DeleteButton from 'components/delete-button';

const types = {
  email: 'email',
  password: 'password',
  tel: 'tel',
  number: 'number',
  text: 'text',
  url: 'url',
  textarea: 'textarea',
  search: 'search'
};

const themes = {
  default: 'default',
  shortInput: 'short-input',
  simple: 'simple',
  confirmationInput: 'confirmation-input'
};

const TextInput = React.forwardRef(
  (
    {
      label,
      isRequired,
      placeholder,
      type,
      id,
      value,
      onChange,
      onKeyPress,
      name,
      shouldAutoFocus,
      theme,
      error,
      errorText,
      className,
      readOnly,
      step = 1,
      min = 0,
      max,
      autoComplete = null,
      pattern,
      maxLength,
      infoNote,
      disabled = false,
      useValue = false,
      deleteButtonText,
      onDelete,
      hideLabel,
      noteClassName
    },
    ref
  ) => {
    const [isValue, setIsValue] = React.useState(value);
    const handleChange = e => {
      setIsValue(e.target.value);
      onChange(e);
    };

    React.useEffect(() => {
      setIsValue(value);
    }, [value]);

    const inputName = name || id;

    let inputProps;

    if (useValue) {
      inputProps = {
        className: cn('text-input__input', {
          'text-input__input--error': error,
          [`text-input__input--${theme}`]: theme
        }),
        id,
        ref: ref,
        name: inputName,
        value: isValue,
        autoFocus: shouldAutoFocus,
        onChange: e => {
          handleChange(e);
        },
        onKeyPress,
        placeholder,
        type: type !== types.textarea ? type : undefined,
        required: isRequired,
        readOnly,
        step: type === 'number' ? step : null,
        min: type === 'number' ? min : null,
        max: type === 'number' ? max : null,
        autoComplete,
        pattern: pattern,
        maxLength: maxLength,
        disabled: disabled
      };
    } else {
      inputProps = {
        className: cn('text-input__input', {
          'text-input__input--error': error,
          [`text-input__input--${theme}`]: theme
        }),
        id,
        ref: ref,
        name: inputName,
        defaultValue: isValue,
        autoFocus: shouldAutoFocus,
        onChange: e => {
          handleChange(e);
        },
        onKeyPress,
        placeholder,
        type: type !== types.textarea ? type : undefined,
        required: isRequired,
        readOnly,
        step: type === 'number' ? step : null,
        min: type === 'number' ? min : null,
        max: type === 'number' ? max : null,
        autoComplete,
        pattern: pattern,
        maxLength: maxLength,
        disabled: disabled
      };
    }

    const inputTag = React.createElement(
      type === types.textarea ? 'textarea' : 'input',
      inputProps
    );

    return (
      <div
        className={cn('text-input', className, {
          'text-input--required': isRequired,
          'text-input--error': error,
          [`text-input--${types[type]}`]: types[type]
        })}
      >
        {label && (
          <Label
            className={cn('text-input__label', {
              [`text-input__label--${theme}`]: theme,
              'text-input__label--hidden': hideLabel
            })}
            htmlFor={inputName}
            error={error}
            text={label}
          />
        )}
        {infoNote ? (
          <div className="text-input__input-and-info-container">
            <div className="text-input__input-container">{inputTag}</div>
            <div className="text-input__note-container">
              <InfoNote {...infoNote} className={noteClassName} />
            </div>
          </div>
        ) : (
          <React.Fragment>
            {deleteButtonText ? (
              <div className="text-input__input-wrapper">
                {inputTag}
                <DeleteButton
                  text={deleteButtonText}
                  className="text-input__delete-button delete-button--in-text-input"
                  onClick={e => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </div>
            ) : (
              <React.Fragment>{inputTag}</React.Fragment>
            )}
          </React.Fragment>
        )}
        {error && errorText && (
          <ErrorText className="text-input__error" text={errorText} />
        )}
      </div>
    );
  }
);

TextInput.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  theme: PropTypes.oneOf(Object.values(themes)),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string.isRequired,
  shouldAutoFocus: PropTypes.bool,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  autoComplete: PropTypes.string,
  pattern: PropTypes.string,
  maxLength: PropTypes.number,
  infoNote: PropTypes.exact(InfoNote.propTypes),
  disabled: PropTypes.bool,
  useValue: PropTypes.bool,
  deleteButtonText: PropTypes.string,
  onDelete: PropTypes.func,
  hideLabel: PropTypes.bool,
  noteClassName: PropTypes.string
};

TextInput.defaultProps = {
  value: '',
  type: types.text,
  theme: themes.default,
  readonly: false,
  onChange: () => {},
  onKeyPress: () => {},
  onDelete: () => {}
};

TextInput.themes = themes;
TextInput.types = types;

TextInput.propTypesMeta = {
  themes: 'exclude',
  types: 'exclude',
  onKeyPress: 'exclude',
  onChange: 'exclude',
  step: 'exclude',
  min: 'exclude',
  max: 'exclude',
  autoComplete: 'exclude',
  pattern: 'exclude',
  maxLength: 'exclude',
  disabled: 'exclude',
  useValue: 'exclude',
  deleteButtonText: 'exclude',
  onDelete: 'exclude',
  error: 'exclude',
  hideLabel: 'exclude',
  noteClassName: 'exclude'
};

export default TextInput;
