import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TrashCan = ({ className }) => {
  return (
    <div className={cn('portal-delete-button__trash-can', className)}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
      >
        <defs>
          <clipPath>
            <rect
              className="cls-1"
              x="224.12"
              y="-297.58"
              width="10"
              height="14"
              rx="2"
            />
          </clipPath>
          <clipPath>
            <circle className="cls-1" cx="229.12" cy="-286.58" r="1" />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="224.12"
              y="-289.58"
              width="10"
              height="1"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="143.57"
              y="-127.69"
              width="10"
              height="14"
              rx="2"
            />
          </clipPath>
          <clipPath>
            <circle className="cls-1" cx="148.57" cy="-116.69" r="1" />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="143.57"
              y="-119.69"
              width="10"
              height="1"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="539.06"
              y="123.41"
              width="1"
              height="16"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="547.03"
              y="116.41"
              width="1"
              height="14"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="548.68"
              y="116.03"
              width="1"
              height="5"
              transform="translate(77.04 423.05) rotate(-45)"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="543.39"
              y="118.03"
              width="5"
              height="1"
              transform="translate(76.07 420.72) rotate(-45)"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="539.06"
              y="122.41"
              width="6"
              height="1"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="550.06"
              y="122.41"
              width="6"
              height="1"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="539.06"
              y="138.41"
              width="17"
              height="1"
            />
          </clipPath>
          <clipPath>
            <rect
              className="cls-1"
              x="555.06"
              y="123.41"
              width="1"
              height="16"
            />
          </clipPath>
        </defs>
        <path
          className="cls-16"
          d="M19.13,9.9V22.42a.46.46,0,0,1-.43.48H11.56a.46.46,0,0,1-.43-.48V9.9h8m1-1h-10V22.42a1.45,1.45,0,0,0,1.43,1.48H18.7a1.45,1.45,0,0,0,1.43-1.48V8.9Z"
        />
        <path
          className="portal-delete-button__trash-can-lid"
          d="M15.94,4.13,19.25,6a.56.56,0,0,1-.09.33l-.83,1.45h0l-3.47-2,.83-1.45a.66.66,0,0,1,.25-.24m0-1a1.39,1.39,0,0,0-1.13.75L14,5.32l-2.6-1.5-.5.86,2.6,1.5,5.19,3,2.6,1.5.5-.86-2.6-1.5L20,6.87a1.21,1.21,0,0,0-.19-1.65l-3.47-2a.79.79,0,0,0-.4-.1Z"
        />
        <path
          className="cls-16"
          d="M13.64,19.68c-.28,0-.5-.14-.5-.32V13c0-.18.22-.32.5-.32s.5.14.5.32v6.36C14.14,19.54,13.91,19.68,13.64,19.68Z"
        />
        <path
          className="cls-16"
          d="M16.63,19.68c-.28,0-.5-.14-.5-.32V13c0-.18.22-.32.5-.32s.5.14.5.32v6.36C17.13,19.54,16.91,19.68,16.63,19.68Z"
        />
      </svg>
    </div>
  );
};

TrashCan.propTypes = {
  className: PropTypes.string
};

TrashCan.propTypesMeta = 'exclude';

export default TrashCan;
