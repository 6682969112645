import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';

import cn from 'classnames';

const HousingProjectPromoBlock = ({
  title,
  image,
  linkText,
  linkUrl,
  theme
}) => {
  return (
    <div
      className={cn('housing-project-promo-block', {
        [`housing-project-promo-block--${theme}`]: theme
      })}
    >
      <ContentWrapper>
        <a className="housing-project-promo-block__content" href={linkUrl}>
          <div className="housing-project-promo-block__text-container">
            {title && (
              <Heading
                level={2}
                theme={Heading.themes.h2}
                className="housing-project-promo-block__title"
              >
                {title}
              </Heading>
            )}
            {linkText && (
              <span className="housing-project-promo-block__link">
                {linkText}
              </span>
            )}
          </div>

          {image && (
            <div className="housing-project-promo-block__image-container">
              <div className="housing-project-promo-block__image">
                <Image theme={Image.themes.background} {...image} />
              </div>
            </div>
          )}
        </a>
      </ContentWrapper>
    </div>
  );
};

HousingProjectPromoBlock.propTypes = {
  title: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  theme: PropTypes.string
};

HousingProjectPromoBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectPromoBlock;
