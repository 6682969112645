import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import Link from 'components/link';

import cn from 'classnames';

const HousingProjectBlockHeader = ({ title, description, link, theme }) => {
  return (
    <div
      className={cn('housing-project-block-header', {
        [`housing-project-block-header--${theme}`]: theme
      })}
    >
      {title && (
        <div className="housing-project-block-header__title">
          <Heading level={2} theme={Heading.themes.h1}>
            {title}
          </Heading>
        </div>
      )}
      {description && (
        <div
          className={cn('housing-project-block-header__description', {
            'housing-project-block-header__description-low-margin':
              description && link
          })}
        >
          {description}
        </div>
      )}

      {link && (
        <div className="housing-project-block-header__link-container">
          <Link
            animation="shrink"
            className="housing-project-block-header__link"
            {...link}
          />
        </div>
      )}
    </div>
  );
};

HousingProjectBlockHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  theme: PropTypes.string
};

HousingProjectBlockHeader.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectBlockHeader;
