import analytics from 'js/ga-tracking';

const useGaTracking = (category, action, label, url, shouldOpenInNewTab) => {
  analytics.trackGenericInteraction(category, action, label);
  if (url) {
    window.open(url, shouldOpenInNewTab ? '_blank' : '_self');
  }
};

export default useGaTracking;
