import React from 'react';
import PropTypes from 'prop-types';

import ContactCard from 'components/contact-card';
import Columns from 'components/columns';
import Form from 'components/form';
import Heading from 'components/heading';
import Text from 'components/text';

const ContactBlock = ({ contacts, form, ingress, title }) => {
  return (
    <div className="contact-block">
      <Columns themes={[Columns.themes.compactMobile]}>
        <Columns.Main>
          <Heading theme={Heading.themes.h2}>{title}</Heading>
          <Text theme={Text.themes.ingressSmall}>{ingress}</Text>
          {form && (
            <div className="contact-block__form">
              <Form {...form} />
            </div>
          )}
        </Columns.Main>

        <Columns.Sidebar>
          <div className="contact-block__contacts">
            {contacts.map(contact => (
              <div
                className="contact-block__card"
                key={contact.name + contact.jobTitle}
              >
                <ContactCard
                  themes={[
                    ContactCard.themes.smallText,
                    ContactCard.themes.verticalMobile
                  ]}
                  {...contact}
                />
              </div>
            ))}
          </div>
        </Columns.Sidebar>
      </Columns>
    </div>
  );
};

ContactBlock.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.exact(ContactCard.propTypes)),
  form: PropTypes.exact(Form.propTypes),
  ingress: PropTypes.string,
  title: PropTypes.string
};

ContactBlock.defaultProps = {
  contacts: []
};

export default ContactBlock;
