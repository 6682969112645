import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Video from 'components/video';
import cn from 'classnames';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import Plx from 'react-plx';
import RichText from 'components/rich-text';
import Button from 'components/button';

const HousingProjectMediaBlock = ({
  title,
  intro,
  image,
  video,
  link,
  theme,
  id
}) => {
  const plxData = [
    {
      start: `.housing-project-media-block__wrapper--${id}`,
      duration: '150vh',
      startOffset: '100vh',
      properties: [
        {
          startValue: 0,
          endValue: -1000,
          property: 'translateY'
        }
      ]
    }
  ];
  return (
    <div
      className={cn(
        `housing-project-media-block__wrapper housing-project-media-block__wrapper--${id}`,
        {
          [`housing-project-media-block--${theme}`]: theme,
          'housing-project-media-block__wrapper--has-video': video,
          'housing-project-media-block__wrapper--short': !title && !intro
        }
      )}
    >
      <div
        className={cn('housing-project-media-block', {
          'housing-project-media-block--short': !title && !intro
        })}
      >
        {video && (
          <Video
            {...video}
            shouldLoop
            containerClassName="video-container--in-housing-project-media-block"
            videoClassName="video--in-housing-project-media-block"
          />
        )}
        {image && !video && (
          <Image
            theme={Image.themes.background}
            {...image}
            className="housing-project-media-block__image"
          />
        )}
        <div className="child-wrapper">
          <ContentWrapper className="housing-project-media-block__content">
            <Plx parallaxData={plxData}>
              {title && (
                <div className="housing-project-media-block__heading">
                  <Heading level={2} theme={Heading.themes.h1}>
                    <span
                      className={cn(
                        'housing-project-media-block__heading-text',
                        {
                          [`housing-project-media-block__heading-text--background-${theme}`]: theme
                        }
                      )}
                    >
                      {title}
                    </span>
                  </Heading>
                </div>
              )}

              {intro && (
                <div
                  className={cn('housing-project-media-block__intro', {
                    [`housing-project-media-block__intro--background-${theme}`]: theme
                  })}
                >
                  <RichText {...intro} />
                  {link && (
                    <div className="housing-project-media-block__link">
                      <Button
                        housingProjectBaseTheme={
                          theme === 'green' || theme === 'dark'
                            ? 'dark'
                            : 'light'
                        }
                        housingProjectColorTheme={
                          theme === 'green' ? theme : null
                        }
                        housingProjectButtonTheme="primary"
                        alignment={'center'}
                        {...link}
                      />
                    </div>
                  )}
                </div>
              )}
            </Plx>
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

HousingProjectMediaBlock.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.exact(RichText.propTypes),
  id: PropTypes.string,
  link: PropTypes.exact(Button.propTypes),
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes),
  theme: PropTypes.string
};

HousingProjectMediaBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectMediaBlock;
