import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Footer from 'components/footer';
import Header from 'components/header';
import Support from 'components/support';
import NewsModal from 'components/news-modal';
import GlobalWarningBlock from 'components/global-warning-block';

import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

const themes = {
  noMinHeight: 'no-min-height',
  housingProject: 'housing-project'
};

const Layout = ({
  skipToMainContentLinkText,
  children,
  footer,
  fullWidthChildren,
  header,
  support,
  supportThemes,
  theme,
  newsModal
}) => {
  const [warningBlockIsOpen, setWarningBlockIsOpen] = useState(false);

  return (
    <React.Fragment>
      <SkipNavLink>{skipToMainContentLinkText}</SkipNavLink>
      {header?.globalWarningBlock && (
        <GlobalWarningBlock
          {...header?.globalWarningBlock}
          isOpen={warningBlockIsOpen}
          handleIsOpen={setWarningBlockIsOpen}
          isPlaceholder={true}
        />
      )}
      {header && (
        <Header
          {...header}
          handleChangeWarningBlock={setWarningBlockIsOpen}
          globalWarningBlockIsOpen={warningBlockIsOpen}
        />
      )}
      {(support || children) && (
        <main className={cn('layout', { [`layout--${theme}`]: theme })}>
          {support && <Support themes={supportThemes} {...support} />}
          <SkipNavContent>
            {fullWidthChildren}
            <div className="layout__content">{children}</div>
          </SkipNavContent>
        </main>
      )}

      {newsModal && <NewsModal {...newsModal} />}
      {footer && <Footer {...footer} theme={theme} />}
    </React.Fragment>
  );
};

Layout.propTypes = {
  skipToMainContentLinkText: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.exact(Footer.propTypes),
  fullWidthChildren: PropTypes.node,
  header: PropTypes.exact(Header.propTypes),
  headerTheme: PropTypes.any,
  support: PropTypes.exact(Support.propTypes),
  supportThemes: PropTypes.any,
  theme: PropTypes.oneOf(Object.values(themes)),
  newsModal: PropTypes.exact(NewsModal.propTypes)
};

Layout.propTypesMeta = {
  headerTheme: 'exclude',
  supportThemes: 'exclude',
  theme: 'exclude'
};

Layout.themes = themes;

export default Layout;
