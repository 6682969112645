import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentWrapper from 'components/content-wrapper';
import Heading from 'components/heading';
import Image from 'components/image';
import LinkList from 'components/link-list';
import Text from 'components/text';
import EstimatedReadingTime from 'components/estimated-reading-time';
import ColoredFrame from 'components/colored-frame';

const ArticleHeader = ({
  image,
  ingress,
  linkList,
  metadata,
  title,
  estimatedReadingTime,
  coloredFrame
}) => (
  <div className="article-header">
    <ContentWrapper>
      <div
        className={cn('article-header__content', {
          'article-header__content--no-image': !image
        })}
      >
        <div className="article-header__left-content">
          <div className="article-header__heading">
            <Heading level={1} theme={Heading.themes.h1}>
              {title}
            </Heading>
          </div>
          {estimatedReadingTime && (
            <EstimatedReadingTime {...estimatedReadingTime} />
          )}
          {metadata.length > 0 && (
            <div className="article-header__metadata">
              {metadata.map(text => (
                <div className="article-header__metadata-item" key={text}>
                  {text}
                </div>
              ))}
            </div>
          )}

          <div className="article-header__ingress">
            <Text theme="ingress">{ingress}</Text>
          </div>
          {coloredFrame && (
            <div className="article-header__colored-frame">
              <ColoredFrame {...coloredFrame} />
            </div>
          )}
        </div>
        {image && (
          <>
            <div className="article-header__right-content">
              <div className="article-header__image">
                <Image theme={Image.themes.background} {...image}></Image>
              </div>
            </div>
            <div className="article-header__corner" />
          </>
        )}
      </div>

      {linkList && (
        <div className="article-header__links">
          <LinkList
            themes={[LinkList.themes.big, LinkList.themes.columns]}
            {...linkList}
          />
        </div>
      )}
    </ContentWrapper>
  </div>
);

ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.exact(Image.propTypes),
  ingress: PropTypes.string,
  linkList: PropTypes.exact(LinkList.propTypes),
  metadata: PropTypes.arrayOf(PropTypes.string),
  estimatedReadingTime: PropTypes.exact(EstimatedReadingTime.propTypes),
  coloredFrame: PropTypes.exact(ColoredFrame.propTypes)
};

ArticleHeader.defaultProps = {
  metadata: []
};

export default ArticleHeader;
