import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import ContentWrapper from 'components/content-wrapper';
import Image from 'components/image';
import cn from 'classnames';
import Button from 'components/button';
import useClickOutside from 'hooks/use-click-outside';
import Icon from 'components/icon';

const HousingProjectMenu = ({
  logo,
  mobileLogo,
  links,
  button,
  mobileButtonText,
  backToFrontpageLink,
  isArticlePage
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasClickedMenuOnce, setHasClickedMenuOnce] = React.useState(false);
  const menuRef = React.useRef();

  const toggle = () => {
    setIsOpen(!isOpen);
    if (!hasClickedMenuOnce) {
      setHasClickedMenuOnce(true);
    }
  };

  useClickOutside(
    menuRef,
    () => {
      setIsOpen(false);
    },
    'housing-project-menu__mobile-button'
  );

  return (
    <div
      className={cn('housing-project-menu', {
        'housing-project-menu--is-open': isOpen,
        'housing-project-menu--is-closed': !isOpen && hasClickedMenuOnce,
        'housing-project-menu--is-article-page': isArticlePage
      })}
    >
      <ContentWrapper>
        <div ref={menuRef} className="housing-project-menu__content-container">
          <nav
            className="housing-project-menu__content"
            aria-label="secondary menu"
          >
            {logo && (
              <div className="housing-project-menu__logo">
                <Image {...logo} />
              </div>
            )}

            {links && links.length !== 0 && (
              <div className="housing-project-menu__list-container">
                <div className="housing-project-menu__close-button-container">
                  <button
                    className="housing-project-menu__close-button"
                    onClick={toggle}
                    aria-label="Close"
                  >
                    <Icon name="x" />
                  </button>
                </div>
                <ul className="housing-project-menu__list">
                  {links.map(link => (
                    <li
                      key={link.text}
                      className="housing-project-menu__list-item"
                    >
                      <Link
                        className="housing-project-menu__list-link"
                        animation="expand"
                        {...link}
                      />
                    </li>
                  ))}
                </ul>
                {button && (
                  <div className="housing-project-menu__button-container">
                    <Button
                      className="housing-project-menu__button"
                      housingProjectBaseTheme="dark"
                      housingProjectButtonTheme="primary"
                      {...button}
                    />
                  </div>
                )}
                {backToFrontpageLink && (
                  <a
                    href={backToFrontpageLink.url}
                    className="housing-project-menu__back-to-frontpage-link"
                  >
                    {mobileLogo && (
                      <div className="housing-project-menu__logo--mobile">
                        <Image {...mobileLogo} />
                      </div>
                    )}
                    <span className="housing-project-menu__back-to-frontpage-link-text">
                      {backToFrontpageLink.text}
                    </span>
                  </a>
                )}
              </div>
            )}
          </nav>
          {mobileButtonText && (
            <button
              onClick={toggle}
              className="housing-project-menu__mobile-button"
            >
              {mobileLogo && (
                <div className="housing-project-menu__logo--mobile">
                  <Image {...mobileLogo} />
                </div>
              )}
              <span className="housing-project-menu__mobile-button-text">
                {mobileButtonText}
              </span>
            </button>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectMenu.propTypes = {
  logo: PropTypes.exact(Image.propTypes),
  mobileLogo: PropTypes.exact(Image.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  button: PropTypes.exact(Button.propTypes),
  mobileButtonText: PropTypes.string,
  backToFrontpageLink: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string
  }),
  isArticlePage: PropTypes.bool
};

HousingProjectMenu.propTypesMeta = {
  isArticlePage: 'exclude'
};

export default HousingProjectMenu;
