import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useOnScrollPosition from 'hooks/use-on-scroll-position';
import useToggle from 'hooks/use-toggle';

import ContactForm from 'components/contact-form';
import SupportLinks from 'components/support-links';
import TabTrapper from 'components/tab-trapper';
import Icon from 'components/icon';

const themes = {
  fullWidth: 'full-width'
};

const linkColorThemes = {
  orange: 'orange',
  aqua: 'aqua',
  pink: 'pink',
  pinkLight: 'pink-light'
};

const Support = ({
  contactForm,
  contactFormButtonText,
  link,
  linkColorTheme,
  links,
  linksButtonText,
  themes,
  contactFormVisible,
  chat
}) => {
  const [contactVisible, _toggleContact, hideContact, showContact] = useToggle(
    contactFormVisible
  );
  const [linksVisible, _toggleLinks, hideLinks, showLinks] = useToggle(false);

  const [hasScrolled, setHasScrolled] = React.useState(false);
  useOnScrollPosition(100, () => {
    setHasScrolled(true);
  });

  if (typeof window !== 'undefined' && window.zissonWebChat && chat) {
    window.zissonWebChat.hideWidget();

    var inputDefaults = {
      navn: `${chat.name}`,
      telefonnummer: `${chat.phone}`
    };

    if (chat.name !== '' && chat.phone !== '') {
      window.zissonWebChat.setDefaults(inputDefaults);
    }

    const button = document.getElementById('chat-button');

    button.addEventListener('click', function() {
      if (chat.name !== '' && chat.phone !== '') {
        setTimeout(function() {
          window.zissonWebChat.openWidget();
        }, 200);
        window.zissonWebChat.startConversation();
      } else {
        window.zissonWebChat.openWidget();
      }
    });
  }

  const linkBgColor = linkColorTheme ? linkColorTheme : linkColorThemes.orange;

  React.useEffect(() => {
    if (chat && chat.id && chat.bootstrapperUrl) {
      var script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('data-jwt', `${chat.id}`);
      script.setAttribute('src', `${chat.bootstrapperUrl}`);

      document.body.appendChild(script);
    }
  }, []);

  return (
    <React.Fragment>
      <div
        className={cn(
          'support',
          themes.map(theme => `support--${theme}`)
        )}
      >
        <div
          className={cn('support__buttons', {
            'support__buttons--offset': !hasScrolled
          })}
        >
          {link && (
            <a
              className={cn('support__link', {
                ['support__link--' + linkBgColor]: linkBgColor
              })}
              href={link.url}
            >
              <span>{link.text}</span>
            </a>
          )}
          {contactForm && (
            <button
              className="support__button"
              onClick={showContact}
              type="button"
            >
              <span>{contactFormButtonText}</span>
            </button>
          )}
          {links && (
            <button
              className="support__button"
              onClick={showLinks}
              type="button"
            >
              <span>{linksButtonText}</span>
            </button>
          )}
          {chat && chat.id && chat.bootstrapperUrl && (
            <button id="chat-button" className="support__button">
              <Icon
                useFill
                className="support__button-chat-icon"
                name="chat"
              ></Icon>
              <span>{chat.text}</span>
            </button>
          )}
        </div>
      </div>

      {contactForm && (
        <div className="support__contact-form-container">
          <TabTrapper isActive={contactVisible}>
            {contactVisible && (
              <ContactForm hide={hideContact} {...contactForm} />
            )}
          </TabTrapper>
        </div>
      )}
      {links && (
        <div className="support__support-links-container">
          <TabTrapper isActive={linksVisible}>
            {linksVisible && <SupportLinks hide={hideLinks} {...links} />}
          </TabTrapper>
        </div>
      )}
    </React.Fragment>
  );
};

Support.propTypes = {
  contactForm: PropTypes.exact(ContactForm.propTypes),
  contactFormButtonText: PropTypes.string,
  contactFormVisible: PropTypes.bool,
  chat: PropTypes.shape({
    id: PropTypes.string,
    bootstrapperUrl: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string
  }),
  link: PropTypes.exact({
    url: PropTypes.string,
    text: PropTypes.string
  }),
  links: PropTypes.exact(SupportLinks.propTypes),
  linksButtonText: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes))),
  linkColorTheme: PropTypes.oneOf(Object.values(linkColorThemes))
};

Support.propTypesMeta = {
  themes: 'exclude'
};

Support.defaultProps = {
  themes: []
};

Support.themes = themes;

export default Support;
