import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from 'components/content-wrapper';
import Heading from 'components/heading';
import cn from 'classnames';

const HousingProjectArticleHeader = ({ title, lead, theme, className }) => {
  return (
    <ContentWrapper>
      <div
        className={cn('housing-project-article-header', {
          [`housing-project-article-header--${theme}`]: theme,
          [`housing-project-article-header--${className}`]: className,
          'housing-project-article-header--has-lead': lead
        })}
      >
        {title && (
          <Heading
            className="housing-project-article-header__title"
            level={1}
            theme={Heading.themes.h1}
          >
            {title}
          </Heading>
        )}
        {lead && (
          <div className="housing-project-article-header__lead">{lead}</div>
        )}
      </div>
    </ContentWrapper>
  );
};

HousingProjectArticleHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string
};

HousingProjectArticleHeader.propTypesMeta = {
  theme: 'exclude',
  className: 'exclude'
};

export default HousingProjectArticleHeader;
