import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Heading from 'components/heading';
import Birds from './birds';

const LoadingIndicator = ({
  text,
  isFullScreen,
  withTransparentBg,
  isLoading,
  spinner
}) => {
  return (
    <div
      className={cn('loading-indicator', {
        'loading-indicator--is-full-screen': isFullScreen,
        'loading-indicator--is-loading': isLoading,
        'loading-indicator--is-transparent': withTransparentBg,
        'loading-indicator--spinner': spinner
      })}
    >
      {spinner && (
        <div className="loading-indicator__spinner-container">
          <div className="loading-indicator__spinner"></div>
        </div>
      )}
      {isFullScreen && (
        <div className="loading-indicator__content">
          <Heading
            className="loading-indicator__text"
            level={2}
            theme={Heading.themes.h2}
          >
            {text}
          </Heading>
          <div className="loading-indicator__birds">
            <Birds />
          </div>
        </div>
      )}
    </div>
  );
};

LoadingIndicator.propTypes = {
  text: PropTypes.string,
  isFullScreen: PropTypes.bool,
  withTransparentBg: PropTypes.bool,
  isLoading: PropTypes.bool,
  spinner: PropTypes.bool
};

LoadingIndicator.propTypesMeta = {
  isFullScreen: 'exclude',
  withTransparentBg: 'exclude',
  isLoading: 'exclude',
  spinner: 'exclude'
};

export default LoadingIndicator;
