/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import RelatedArticlesCarouselSlide from './related-articles-carousel-slide';
import Heading from 'components/heading';
import RelatedArticlesCarouselWrapper from './related-articles-carousel-wrapper';

import cn from 'classnames';
import Icon from 'components/icon';

const themes = {
  dark: 'dark',
  light: 'light'
};

const RelatedArticlesCarousel = ({
  title,
  previousButtonText,
  nextButtonText,
  slides,
  theme,
  inHousingProjectArticle = false
}) => {
  const [isTitleVisible, setIsTitleVisible] = React.useState(false);
  const [isSlidesVisible, setIsSlidesVisible] = React.useState(false);

  const params = {
    initialSlide: 0,
    centeredSlides: false,
    firstPrevElement: '.first',
    lastNextElement: '.last',
    spaceBetween: 24,
    slidesPerView: 'auto',
    breakpoints: {
      800: {
        spaceBetween: 40
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    renderPrevButton: () => (
      <button
        aria-label={previousButtonText}
        className="related-articles-carousel__navigation-button swiper-button-prev"
      >
        <div className="related-articles-carousel__navigation-button__icon-container">
          <Icon
            className="related-articles-carousel__navigation-button__icon related-articles-carousel__navigation-button__icon__prev"
            name="chevron-big-right"
          />
        </div>
      </button>
    ),
    renderNextButton: () => (
      <button
        aria-label={nextButtonText}
        className="related-articles-carousel__navigation-button swiper-button-next"
      >
        <div className="related-articles-carousel__navigation-button__icon-container">
          <Icon
            className="related-articles-carousel__navigation-button__icon related-articles-carousel__navigation-button__icon__next"
            name="chevron-big-right"
          />
        </div>
      </button>
    )
  };

  const titleRef = React.useRef();
  const slidesRef = React.useRef();

  React.useEffect(() => {
    if ('IntersectionObserver' in window) {
      const titleObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsTitleVisible(true);
            titleObserver.disconnect();
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.4 }
      );

      const slidesObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsSlidesVisible(true);
            slidesObserver.disconnect();
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.4 }
      );

      if (titleRef.current) {
        titleObserver.observe(titleRef.current);
      }
      if (slidesRef.current) {
        slidesObserver.observe(slidesRef.current);
      }
    }
  }, []);

  return (
    <RelatedArticlesCarouselWrapper renderWrapper={inHousingProjectArticle}>
      <div
        className={cn('related-articles-carousel', {
          [`related-articles-carousel--${theme}`]: theme
        })}
      >
        {title && (
          <div
            ref={titleRef}
            className={cn('related-articles-carousel__title-container', {
              'related-articles-carousel__title-container--visible': isTitleVisible
            })}
          >
            <Heading
              className="related-articles-carousel__title"
              level={2}
              theme={Heading.themes.h1}
            >
              {title}
            </Heading>
          </div>
        )}
        <div
          ref={slidesRef}
          className={cn('related-articles-carousel__slides-container', {
            'related-articles-carousel__slides-container--visible': isSlidesVisible
          })}
        >
          <div className="related-articles-carousel__slides">
            <Swiper {...params}>
              {slides &&
                slides.map((item, index) => {
                  const itemIndex = index;
                  return (
                    <RelatedArticlesCarouselSlide
                      {...item}
                      key={item.title + item.tag + itemIndex}
                    />
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    </RelatedArticlesCarouselWrapper>
  );
};

RelatedArticlesCarousel.propTypes = {
  inHousingProjectArticle: PropTypes.bool,
  title: PropTypes.string,
  nextButtonText: PropTypes.string.isRequired,
  previousButtonText: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.exact(RelatedArticlesCarouselSlide.propTypes)
  ),
  theme: PropTypes.oneOf(Object.values(themes))
};

RelatedArticlesCarousel.propTypesMeta = {
  theme: 'exclude'
};

RelatedArticlesCarousel.themes = themes;

export default RelatedArticlesCarousel;
