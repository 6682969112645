import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Image from 'components/image';

const LogoutConfirmation = ({ heading, text, link, image }) => {
  return (
    <div className="logout-confirmation">
      {image && (
        <div className="logout-confirmation__image-container">
          <Image {...image} className="logout-confirmation__image" />
        </div>
      )}
      <div className="logout-confirmation__text-container">
        {heading && <h2 className="logout-confirmation__heading">{heading}</h2>}
        {text && <div className="logout-confirmation__text">{text}</div>}
        {link && <Link {...link} animation="shrink" />}
      </div>
    </div>
  );
};

LogoutConfirmation.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(Image.propTypes)
};

export default LogoutConfirmation;
