import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Plx from 'react-plx';

import Image from 'components/image';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';
import RichText from 'components/rich-text';
import Button from 'components/button';

const HousingProjectTeaserBlock = ({
  title,
  text,
  link,
  leftImage,
  rightImage,
  theme
}) => {
  const plxData = [
    {
      start: `.housing-project-teaser-block__left`,
      duration: '75vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  const plxData2 = [
    {
      start: `.housing-project-teaser-block__right`,
      duration: '75vh',
      startOffset: '5vh',
      properties: [
        {
          startValue: 200,
          endValue: 0,
          property: 'translateY'
        }
      ]
    }
  ];

  return (
    <div
      className={cn('housing-project-teaser-block', {
        [`housing-project-teaser-block--${theme}`]: theme
      })}
    >
      <ContentWrapper className="housing-project-teaser-block__wrapper">
        <div className="housing-project-teaser-block__left">
          {leftImage && (
            <Plx
              className="housing-project-teaser-block__plx-wrapper"
              parallaxData={plxData}
            >
              <Image {...leftImage} />
            </Plx>
          )}
          <div className="housing-project-teaser-block__text-content">
            <Heading
              level={2}
              theme={Heading.themes.h1}
              className="housing-project-teaser-block__heading"
            >
              {title}
            </Heading>
            {text && (
              <div className="housing-project-teaser-block__text">
                <RichText
                  {...text}
                  className="rich-text--in-housing-project-teaser-block"
                />
              </div>
            )}
            {link && (
              <div className="housing-project-teaser-block__link">
                <Button
                  housingProjectBaseTheme={
                    theme === 'green' || theme === 'dark' ? 'dark' : 'light'
                  }
                  housingProjectColorTheme={theme === 'green' ? theme : null}
                  housingProjectButtonTheme="primary"
                  alignment={'center'}
                  {...link}
                />
              </div>
            )}
          </div>
        </div>
        <div className="housing-project-teaser-block__right">
          {rightImage && (
            <Plx
              className="housing-project-teaser-block__plx-wrapper"
              parallaxData={plxData2}
            >
              <Image {...rightImage} />
            </Plx>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

HousingProjectTeaserBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Button.propTypes),
  leftImage: PropTypes.exact(Image.propTypes),
  rightImage: PropTypes.exact(Image.propTypes),
  theme: PropTypes.string
};

HousingProjectTeaserBlock.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectTeaserBlock;
