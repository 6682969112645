import React from 'react';
const Birds = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 566 145"
    >
      <defs>
        <style></style>
      </defs>
      <line className="cls-1" x1="8.41" y1="76.87" x2="558.41" y2="76.87" />
      <path
        className="cls-2"
        d="M410.79,32.3c-5.77,1.91-11.18-13.7-16.72-14.89-8.76-1.86-2.37-11.55,3.47-13.25,4.89-1.43,14.55-2.18,17.56,9.22C417.37,22,416.57,30.4,410.79,32.3Z"
      />
      <path
        className="cls-3"
        d="M399.88,19.41C396.69,12.29,391,3,383.32,4.29c2.29,4.46,4.58,8.93,7,13.31,1,1.79,2.21,3.7,4.42,3.85s5.23-2.81,4.16-4.54"
      />
      <circle className="cls-3" cx="396.2" cy="17.53" r="4.9" />
      <path
        className="cls-3"
        d="M371.06,56.81a50.44,50.44,0,0,0,3.17,22.56,52.18,52.18,0,0,0,2.83,6.18,2.35,2.35,0,0,0,4.06-2.37c-.56-1-1.09-2.1-1.57-3.17-.13-.27-.24-.54-.36-.81.18.42-.16-.39-.19-.46-.22-.55-.43-1.1-.63-1.66a48.27,48.27,0,0,1-1.89-6.7c-.11-.54-.21-1.08-.3-1.63l-.15-.87c0-.09-.13-1-.07-.47-.15-1.17-.26-2.35-.32-3.53a49.09,49.09,0,0,1,.12-7.07,2.36,2.36,0,0,0-2.35-2.35,2.42,2.42,0,0,0-2.35,2.35Z"
      />
      <path
        className="cls-3"
        d="M376.72,60.51,381,78.87a2.37,2.37,0,0,0,2.89,1.64,2.4,2.4,0,0,0,1.64-2.89l-4.26-18.36a2.37,2.37,0,0,0-2.89-1.64,2.4,2.4,0,0,0-1.64,2.89Z"
      />
      <path
        className="cls-3"
        d="M395,67.86c-.21.79-.45,1.58-.71,2.35-.13.39-.27.77-.42,1.16-.07.19-.14.38-.22.57l-.08.19a35.21,35.21,0,0,1-2.23,4.37c-.42.7-.86,1.39-1.33,2.06l-.72,1c-.06.08-.44.55-.13.17l-.39.48a35.4,35.4,0,0,1-3.35,3.58,2.35,2.35,0,1,0,3.32,3.32,38.23,38.23,0,0,0,10.79-18A2.35,2.35,0,0,0,395,67.86Z"
      />
      <path
        className="cls-3"
        d="M386.72,19.65a10.8,10.8,0,0,0-2.88,10.61,1.18,1.18,0,0,0,2.27-.62,8.45,8.45,0,0,1,2.27-8.33c1.09-1-.57-2.71-1.66-1.66Z"
      />
      <path
        className="cls-3"
        d="M375.81,86.44a13.89,13.89,0,0,0-9.23,6.11,1.19,1.19,0,0,0,1,1.77l4,.15L371,92.28A17.49,17.49,0,0,0,361.89,105a1.18,1.18,0,0,0,.82,1.44,18.3,18.3,0,0,0,13.75-3.22,1.22,1.22,0,0,0,.42-1.61,1.18,1.18,0,0,0-1.61-.42,16,16,0,0,1-11.94,3l.82,1.45a15.34,15.34,0,0,1,8-11.3,1.18,1.18,0,0,0-.59-2.19l-4-.15,1,1.77a11.59,11.59,0,0,1,7.82-5,1.18,1.18,0,0,0,.82-1.45,1.2,1.2,0,0,0-1.44-.82Z"
      />
      <path
        className="cls-2 wing"
        d="M392.46,73.24c-5.47-2.66,3.21-20.6.66-26.57-4-9.44,15.39-19.46,20-15.33,3.33,3,8,14-.28,29.47C406.62,72.48,397.93,75.9,392.46,73.24Z"
      />
      <path
        className="cls-2"
        d="M378.91,65.44c4.85,1.67,10.37-14.2,15.44-16.24,8-3.23,2.43-20.77-2.8-21.08-3.75-.22-13.12,3.55-17.4,17.73C370.92,56.54,374.06,63.76,378.91,65.44Z"
      />
      <path
        className="cls-4"
        d="M155.69,58.45c5.78,1.91,11.19-13.7,16.73-14.89C181.18,41.7,174.79,32,169,30.31c-4.89-1.43-14.56-2.18-17.57,9.22C149.11,48.12,149.91,56.55,155.69,58.45Z"
      />
      <path
        className="cls-3"
        d="M166.6,45.56c3.2-7.12,8.93-16.38,16.57-15.12-2.29,4.46-4.58,8.93-7,13.31-1,1.79-2.2,3.7-4.42,3.85s-5.23-2.81-4.16-4.54"
      />
      <circle className="cls-3" cx="170.28" cy="43.68" r="4.9" />
      <path
        className="cls-3"
        d="M190.72,83a49.09,49.09,0,0,1,.13,7.07c-.07,1.18-.18,2.36-.33,3.53.07-.51-.05.38-.07.47,0,.29-.09.58-.14.87-.09.55-.2,1.09-.31,1.63a49.65,49.65,0,0,1-1.88,6.7c-.2.56-.41,1.11-.63,1.66,0,.07-.38.88-.19.46l-.36.81c-.48,1.07-1,2.13-1.57,3.17a2.35,2.35,0,1,0,4,2.37,51.5,51.5,0,0,0,6.16-22.33,49,49,0,0,0-.16-6.41,2.35,2.35,0,0,0-4.7,0Z"
      />
      <path
        className="cls-3"
        d="M185.24,85.41,181,103.77A2.35,2.35,0,1,0,185.5,105l4.27-18.36a2.35,2.35,0,0,0-4.53-1.25Z"
      />
      <path
        className="cls-3"
        d="M167,95.26a38.39,38.39,0,0,0,10.79,18,2.35,2.35,0,0,0,3.33-3.32,36.68,36.68,0,0,1-3.36-3.58l-.38-.48c.32.39-.1-.13-.13-.17-.25-.33-.49-.66-.72-1-.47-.67-.91-1.36-1.33-2.06a35.27,35.27,0,0,1-2.24-4.37c.17.39,0,0-.07-.19l-.23-.57c-.13-.35-.26-.71-.38-1.06-.28-.81-.52-1.62-.74-2.45A2.35,2.35,0,0,0,167,95.26Z"
      />
      <path
        className="cls-3"
        d="M178.11,47.46a8.45,8.45,0,0,1,2.27,8.33,1.17,1.17,0,0,0,2.26.62,10.82,10.82,0,0,0-2.87-10.61c-1.09-1-2.76.61-1.66,1.66Z"
      />
      <path
        className="cls-3"
        d="M190.06,114.86a11.59,11.59,0,0,1,7.82,5l1-1.77-3.95.15a1.19,1.19,0,0,0-.6,2.19,15.32,15.32,0,0,1,8,11.3l.83-1.44a16,16,0,0,1-11.94-3,1.18,1.18,0,0,0-1.61.42,1.21,1.21,0,0,0,.42,1.61,18.3,18.3,0,0,0,13.75,3.22,1.19,1.19,0,0,0,.82-1.44,17.51,17.51,0,0,0-9.07-12.71l-.59,2.19,3.95-.15a1.19,1.19,0,0,0,1-1.77,13.89,13.89,0,0,0-9.23-6.11,1.22,1.22,0,0,0-1.45.82,1.2,1.2,0,0,0,.83,1.45Z"
      />
      <path
        className="cls-4 wing--green"
        d="M174,99.39c5.47-2.66-3.21-20.6-.67-26.57,4-9.44-15.39-19.46-20-15.33-3.33,3-8,14,.27,29.47C159.87,98.63,168.56,102.05,174,99.39Z"
      />
      <path
        className="cls-4"
        d="M187.58,91.59c-4.85,1.68-10.37-14.19-15.45-16.24-8-3.23-2.42-20.77,2.8-21.08,3.75-.22,13.13,3.55,17.41,17.74C195.56,82.69,192.42,89.91,187.58,91.59Z"
      />
    </svg>
  );
};

Birds.propTypesMeta = 'exclude';

export default Birds;
