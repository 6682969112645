import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/button';
import Heading from 'components/heading';

const buttonThemes = {
  primary: 'primary',
  secondary: 'secondary'
};

const HousingProjectStatus = ({
  title,
  text,
  link,
  baseTheme,
  colorTheme,
  buttonTheme
}) => {
  return (
    <div
      className={cn('housing-project-status', {
        [`housing-project-status--${baseTheme}`]: baseTheme
      })}
    >
      <div className="housing-project-status__content">
        {title && (
          <Heading level={2} theme={Heading.themes.h3Portal}>
            {title}
          </Heading>
        )}
        <div className="housing-project-status__text">{text}</div>
        {link && (
          <div className="housing-project-status__link">
            <Button
              housingProjectBaseTheme={baseTheme}
              housingProjectButtonTheme={buttonTheme}
              housingProjectColorTheme={colorTheme}
              {...link}
            />
          </div>
        )}
      </div>
    </div>
  );
};

HousingProjectStatus.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.exact(Button.propTypes),
  buttonTheme: PropTypes.oneOf(Object.values(buttonThemes)),
  baseTheme: PropTypes.string,
  colorTheme: PropTypes.string
};

HousingProjectStatus.propTypesMeta = {
  baseTheme: 'exclude',
  colorTheme: 'exclude'
};

export default HousingProjectStatus;
