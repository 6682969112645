import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TrashCan from 'components/trash-can';

const DeleteButton = ({ className, onClick, text }) => {
  return (
    <button
      onClick={onClick}
      className={cn('delete-button', className)}
      aria-label={text}
    >
      <div className="delete-button__circle"></div>
      <TrashCan />
    </button>
  );
};

DeleteButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
};

DeleteButton.propTypesMeta = {
  onClick: 'exclude',
  className: 'exclude',
  text: 'exclude'
};

export default DeleteButton;
